<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        <template v-if="!displayForm('showAddOrderItemForm')">
          Pozīcijas
        </template>
        <template v-else>
          Pievienot papildus pozīciju
        </template>
      </h3>
    </template>
    <template v-slot:buttons>

        <template v-if="!displayForm('showAddOrderItemForm') && this.$userCan('manage_orders') && order.status.id != 3">
          <Button icon="plus" @click="showForm('showAddOrderItemForm')" />
        </template>

        <template v-if="displayForm('showAddOrderItemForm')">
          <Button icon="close" @click="hideAddForm" />
        </template>
    </template>

    <template v-slot:content>
      
      <template v-if="displayForm('showAddOrderItemForm') && this.$userCan('manage_orders')">
        <AddOrderItemForm :order="order"/>
      </template>

      <!--  List all order items  -->
      <template v-if="order.order_items && !displayForm('showAddOrderItemForm')" >
        <div class="divide-y divide-gray-200 dark:divide-gray-700 w-full">
          <SingleOrderItem v-for="(item, index) in order.order_items" :key="index" :item="item" :order="order" :itemsLowOnStock="itemsWithLowStock" />
        </div>
      </template>
            
    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import SingleOrderItem from "@/components/Orders/OrderItems/SingleOrderItem"
import AddOrderItemForm from "@/components/Orders/OrderItems/AddOrderItemForm"

export default {
  name: "AllOrderItems",
  props: ['order', 'orderStatuses', 'itemsWithLowStock'],
  components: {
    ItemCard,
    SingleOrderItem,
    AddOrderItemForm,
  },
  data(){
    return {
      orderAllFromSupplier: false,
      selectedSupplier: null
    }
  },
  computed:{
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    setSupplierForAllItems() {
      if(this.selectedSupplier) {
        if(this.order.order_items) {
          this.order.order_items.forEach(orderItem => {
            orderItem.orderFromSupplier = true
            orderItem.selectedSupplier = this.selectedSupplier
          })
        }
        if(this.order.branch_order_items) {
          for (const [branch, orderItems] of Object.entries(this.order.branch_order_items)) {
            orderItems.forEach(orderItem => {
              orderItem.orderFromSupplier = true
              orderItem.selectedSupplier = this.selectedSupplier
            })
          }
        }
      }
    },
    clearSupplier(){
      this.selectedSupplier = null

      if(this.order.order_items) {

        this.order.order_items.forEach(orderItem => {
          orderItem.orderFromSupplier = false
          orderItem.selectedSupplier = null
        })
      }

      if(this.order.branch_order_items) {
        for (const [branch, orderItems] of Object.entries(this.order.branch_order_items)) {
          orderItems.forEach(orderItem => {
            orderItem.orderFromSupplier = false
            orderItem.selectedSupplier = null
          })
        }
      }
    },
    showDivider(index) {
      return (this.items.length - 1) !== index
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    hideAddForm(){
      this.$store.dispatch('removeAllFormsForDisplay')
    },
  }
}
</script>
