<template>

  <div class="flex gap-3 items-center mt-2 mb-3">
    <Badge text="Viens IMEI / Serial nr / Kods" class="mb-2 md:mb-0" :class="multipleCodsForm ? 'bg-gray-200 text-gray-400' : 'bg-yellow-400 text-yellow-100'" />
    <Switch v-model="multipleCodsForm" color="bg-green-400" />
    <Badge text="Vairāki IMEI / Serial nr / Kodi" class="mb-2 md:mb-0" :class="!multipleCodsForm ? 'bg-gray-200 text-gray-400' : 'bg-green-400 text-green-100'" />
  </div>

  <template v-if="multipleCodsForm">
    <AddSingleOrderItemMultipleCodes :item="item" :order="order" />
  </template>

  <template v-else>
    <AddSingleOrderItemSingleCode :item="item" :order="order" />
  </template>

</template>

<script>
import Switch from "@/components/Components/Switch";
import Badge from "@/components/Components/Badge";
import AddSingleOrderItemSingleCode from "@/components/Orders/OrderItems/AddOrderItemUnits/AddSingleOrderItemSingleCode"
import AddSingleOrderItemMultipleCodes from "@/components/Orders/OrderItems/AddOrderItemUnits/AddSingleOrderItemMultipleCodes"

export default {
  name: "AddSingleOrderItemUniqueCodes",
  components: {
    Switch,
    Badge,
    AddSingleOrderItemSingleCode,
    AddSingleOrderItemMultipleCodes,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      multipleCodsForm: false,
    }
  },
}
</script>

<style>
</style>