<template>
  <div class="rounded-md p-4" :class="bgColor[alert]">
    <div class="flex">
      <template v-if="displayIcon">
        <div class="flex-shrink-0">
          <span :class="icon[alert].color">
            <component
              :is="getIcon(icon[alert].icon)"
              class="w-5 h-5"
            />
          </span>
        </div>
      </template>
      <div class="ml-3">
        <template v-if="text">
          <h3 class="text-sm font-medium" :class="[textArray ? 'mb-2' : '', textColor[alert]]">
            {{text}}
          </h3>
        </template>
        <template v-if="textArray">
          <div class="text-sm"  :class="textColor[alert]">
            <ul role="list" class="list-disc pl-5 space-y-1">
              <template v-for="(text, index) in textArray" :key="index">
                <li>
                  {{text}}
                </li>
              </template>
            </ul>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import {
  ExclamationIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/vue/solid";

export default {
  name: "Alert",
  components: {
    ExclamationIcon,
    CheckCircleIcon,
    XCircleIcon,
  },
  data() {
    return {
      icon: {
        success: {
          icon: 'check-circle',
          color: 'text-green-400'
        },
        warning: {
          icon: 'alert-circle',
          color: 'text-yellow-400',
        },
        error: {
          icon: 'close-circle',
          color: 'text-red-400',
        },
      },
      textColor: {
        success: 'text-green-700',
        warning: 'text-yellow-800',
        error: 'text-red-800',
      },
      bgColor: {
        success: 'bg-green-50',
        warning: 'bg-yellow-50',
        error: 'bg-red-50',
      }
    }
  },
  props: {
    text: {
      type: String,
      default: null
    },
    textArray: {
      type: Array,
      default: null
    },
    alert: {
      type: String,
      default: 'success'
    },
    displayIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
        getIcon(name) {
      const icons = {
        "check-circle": CheckCircleIcon,
        "alert-circle": ExclamationIcon,
        "close-circle": XCircleIcon,
      };

      return icons[name];
    },
  }
}
</script>