<template>

  <form v-if="form" class="space-y-6 w-full">

    <div class="grid grid-cols-12 gap-6 w-full">

      <template v-if="item.orderable.image_url">
        <div class="col-span-12">
          <img :src="item.orderable.image_url" :alt="`BTE ${item.orderable.name}`" class="max-w-[75px] max-h-[75px] w-full object-contain">
        </div>
      </template>

      <div class="col-span-12 sm:col-span-8">
        <Input name="name" placeholder="Preces nosaukums" v-model="form.orderable.name" :errors="errors['orderable.name']" />
      </div>

      <div class="col-span-12 sm:col-span-4 ">
        <Input name="sku" placeholder="Preces kods" v-model="form.orderable.sku" :errors="errors['orderable.sku']" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
        <Input type="number" name="price" placeholder="Cena" @change="calculateTotals" v-model="form.price" :errors="errors.price" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
        <Input type="number" name="quantity" placeholder="Daudzums" @change="changeQuantity" v-model="form.quantity" :errors="errors.quantity" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
        <Input type="number" name="discount" placeholder="Atlaide %" v-model="form.discount" :errors="errors.discount" @change="calculateTotals" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-2">
        <Input type="number" name="vat_rate" placeholder="PVN %" v-model="form.vat_rate" :errors="errors.vat_rate" @change="calculateTotals" />
      </div>

    </div>


    <div class="grid grid-cols-12 gap-6 pt-4">

      <div class="col-span-12 sm:col-span-6 lg:col-span-3">
        <ItemText title="Summa(bez PVN) EUR" :text="item.total_with_discount" />
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3">
        <ItemText title="PVN EUR" :text="item.vat" />
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3">
        <ItemText title="Summa(ar PVN) EUR" :text="item.total_with_vat" />
      </div>

    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="!loading">
        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button @click.prevent="clearForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>

    </div>

  </form>

</template>

<script>
import { mapGetters } from "vuex";
import Calculations from "@/services/Calculations/calculations";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import ItemText from "@/components/Components/ItemText";

export default {
  name: "EditOrderItem",
  components: {
    Input,
    Loading,
    ItemText
  },
  props: ['item', 'customer', 'disableEditQuantity'],
  data: () => ({
    total: 0,
    form: null,
  }),
  computed: {
    ...mapGetters({
      inputData: "customerInputData",
      loading: "loading",
      errors: 'errors',
    }),
    formValidation() {
      return {
        'orderable.name': {
          rules: ['required']
        },
        'orderable.sku': {
          rules: ['required']
        },
        price: {
          rules: ['required', 'minVal0']
        },
        quantity: {
          rules: ['required']
        },
        discount: {
          rules: ['required']
        },
        vat_rate: {
          rules: ['required']
        },
      }
    },
  },
  methods: {
    changeQuantity() {
      this.calculateTotals()
    },
    calculateTotals() {
      const discountRate = +this.form.discount / 100
      this.form.total = (this.form.price * this.form.quantity).toFixed(2)
      this.form.total_discount = (this.form.total * discountRate).toFixed(2)
      this.form.total_with_discount = (this.form.total - this.form.total_discount).toFixed(2)
      this.form.vat = (Calculations.getVat(this.form.total_with_discount, this.form.vat_rate)).toFixed(2)
      this.form.total_with_vat = ((this.form.total_with_discount * 1) + +this.form.vat).toFixed(2)
    },
    clearForm() {
      this.$store.dispatch('getSingleOrder', this.$route.params.orderId)
      this.$store.dispatch('clearEditableItem');
    },
    setData() {
      let editedOrderItem = {}
      editedOrderItem['description'] = this.form.orderable.name
      editedOrderItem['sku'] = this.form.orderable.sku
      editedOrderItem['price'] = this.form.price
      editedOrderItem['quantity'] = this.form.quantity
      editedOrderItem['total'] = this.form.total
      editedOrderItem['discount'] = this.form.discount
      editedOrderItem['vat_rate'] = this.form.vat_rate
      editedOrderItem['total_discount'] = this.form.total_discount
      editedOrderItem['total_with_discount'] = this.form.total_with_discount
      editedOrderItem['vat'] = this.form.vat
      editedOrderItem['total_with_vat'] = this.form.total_with_vat

      return editedOrderItem
    },
    submit() {
      this.$Progress.start()
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('updateOrderItem', {
          orderId : this.$route.params.orderId,
          orderItemId : this.item.id,
          data: this.setData(),
        })
      }  else this.$Progress.fail()
    }
  },
  mounted() {
    this.$store.dispatch("setErrors", {});
    this.$store.dispatch("getCustomerInputData");
    this.form = this.item
  },
}
</script>

<style>
</style>