<template>
    <li>
        <item-card>
            <template v-slot:title>
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                    {{ item.orderable.name }} <span class="font-normal">- {{ item.orderable.category }}</span>
                    <span v-if="item.has_credit_notes" class="ml-2">
                        <Badge text="Kredītrēķins" :className="'bg-red-500 text-white'"/>
                    </span>
                </h3>
            </template>
            <template v-slot:buttons>
                <template v-if="this.$userCan('manage_orders') && order.status.id != 3">
                    <Button icon="pencil" @click="editItem(item)"/>

                    <template v-if="order.order_items_count > 1">
                        <Button icon="delete" @click="deleteOrderItem(item.id)"/>
                    </template>
                </template>

                <template v-if="this.$userCan('manage_orders') && order.status.id === 2">

                    <template
                        v-if="!displayForm(`addUniqueCode_${item.id}`) && item.unique_items.length < item.quantity">
                        <Button icon="plus" @click="showForm(`addUniqueCode_${item.id}`)"
                                tooltip="Pievienot IMEI / Serial nr / Kodus"/>
                    </template>


                </template>

                <template
                    v-if="!displayForm(`addUniqueCode_${item.id}`) && item.unique_items && item.unique_items.length">
                    <Button :icon="showUniqueCodes ? 'chevron-up' : 'chevron-down'"
                            @click="showUniqueCodes = !showUniqueCodes" tooltip="Skatīt IMEI / Serial nr / Kodus"/>
                </template>

            </template>

            <template v-slot:content>
                <template v-if="editableItem.id !== item.id">
                    <item-text title="Preces kods" :text="item.orderable.sku"/>
                    <item-text title="Nosaukmus">
                        <template v-slot:content>
                            <router-link :to="`/catalog/${item.orderable.id}`" target="_blank"
                                         class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1">
                                {{ item.orderable.name }}
                            </router-link>
                        </template>
                    </item-text>

                    <item-text title="Cena Eur" :text="item.price"/>
                    <item-text title="Daudzums" :text="item.quantity"/>
                    <item-text title="Kopā Eur" :text="item.total"/>
                    <item-text title="Atlaide Eur" :text="item.total_discount"/>
                    <item-text title="Summa Eur" :text="item.total_with_discount"/>

                    <div class="w-full"></div>

                    <template v-if="item.orderable.image_url">
                        <item-text>
                            <template v-slot:content>
                                <img :src="item.orderable.image_url" :alt="`BTE ${item.orderable.name}`"
                                     class="max-w-[75px] max-h-[75px] w-full object-contain">
                            </template>
                        </item-text>
                    </template>

                    <template v-if="item.invoice">
                        <item-text title="Rēķins">
                            <template v-slot:content>
                                <a :href="'/invoices/invoice/' + item.invoice.id" target="_blank"
                                   class="dark:text-gray-200 hover:text-main4 dark:hover:text-main4">{{ item.invoice.uuid }}</a>
                            </template>
                        </item-text>
                    </template>

                    <template v-if="order.status.id < 3 && !item.orderable.is_services">
                        <div class="w-full">
                            <template v-if="item.orderable.stock_items.length < 1">
                                <Alert text="Nepietiekams daudzums noliktavā" alert="error"/>
                            </template>

                            <template v-if="item.orderable.stock_items.length > 0">

                                <div class="w-full pt-4 mt-2">
                                    <template v-for="(stockItem, index) in item.orderable.stock_items" :key="index">

                                        <template v-if="!stockItem.is_base_warehouse">
                                            <div class="flex flex-wrap mb-3">
                                                <p class="flex items-center text-base font text-gray-500 mr-3">
                                                    {{ stockItem.warehouse }}
                                                </p>
                                                <p class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
                                                    {{ stockItem.quantity }}
                                                </p>
                                            </div>
                                        </template>

                                        <template v-if="stockItem.is_base_warehouse">
                                            <div class="flex flex-wrap mb-3">
                                                <p class="flex items-center text-base font-bold text-gray-500 mr-3">
                                                    {{ stockItem.warehouse }}
                                                </p>
                                                <p class="mt-2 flex items-center text-md font-bold text-gray-900 dark:text-gray-300 sm:mt-0"
                                                   :class="item.quantity > stockItem.quantity ? 'text-red-500 dark:text-red-500' : 'text-green-500 dark:text-green-500'">
                                                    {{ stockItem.quantity }}
                                                </p>
                                            </div>
                                        </template>

                                        <template v-if="item.quantity > stockItem.quantity">
                                            <Alert text="Nepietiekams daudzums noliktavā" alert="error"/>
                                        </template>

                                    </template>
                                </div>

                            </template>
                        </div>
                    </template>

                    <template v-if="item.unique_items && item.unique_items.length">
                        <div class="flex flex-wrap mb-1">
                            <p class="flex items-center text-base font-bold text-gray-500 mr-3">
                                Pievienoti IMEI / Serial nr / Kodi:
                            </p>
                            <p class="mt-2 flex items-center text-md font-bold text-gray-900 dark:text-gray-300 sm:mt-0"
                               :class="item.unique_items.length < item.quantity ? 'text-red-500 dark:text-red-500' : 'text-green-500 dark:text-green-500'">
                                {{ item.unique_items.length }} / {{ item.quantity }}
                            </p>
                        </div>
                    </template>

                </template>

                <template v-if="editableItem.id === item.id && this.$userCan('manage_orders')">
                    <EditOrderItem :item="item" :customer="order.customer"
                                   :disableEditQuantity="order.status.id > orderStatuses.inFulfillment"
                    />
                </template>

                <template v-if="displayForm(`addUniqueCode_${item.id}`) && order.status.id > orderStatuses.newOrder">
                    <div class="w-full">
                        <AddSingleOrderItemUniqueCodes :item="item" :order="order"/>
                    </div>
                </template>

            </template>

            <template v-slot:additional>
                <template v-if="item.unique_items && item.unique_items.length && showUniqueCodes">
                    <div class="bg-gray-200 dark:bg-gray-800 px-3 py-2 rounded-lg">
                        <template v-for="unit in item.unique_items" :key="unit.id">
                            <SingleOrderItemUnit :item="unit" :order="order"/>
                        </template>
                    </div>
                </template>
            </template>
        </item-card>
    </li>
</template>

<script>
import {mapGetters} from "vuex";
import OrderStatuses from "@/services/Helpers/OrderStatuses";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Alert from "@/components/Components/Alert"
import EditOrderItem from "@/components/Orders/OrderItems/EditOrderItem"
import AddSingleOrderItemUniqueCodes
    from "@/components/Orders/OrderItems/AddOrderItemUnits/AddSingleOrderItemUniqueCodes"
import SingleOrderItemUnit from "@/components/Orders/OrderItems/SingleOrderItemUnit"
import Badge from "../../Components/Badge";

export default {
    name: "SingleOrderItem",
    components: {
        ItemCard,
        ItemText,
        Alert,
        EditOrderItem,
        AddSingleOrderItemUniqueCodes,
        SingleOrderItemUnit,
        Badge
    },
    props: ['order', 'item', 'itemsLowOnStock'],
    data: () => ({
        orderStatuses: null,
        showUniqueCodes: false,
    }),
    created() {
        this.orderStatuses = OrderStatuses
    },
    computed: {
        ...mapGetters({
            editableItem: 'editableItem',
            formsForDisplay: 'formsForDisplay',
        }),
        orderItemIsLowOnStock() {
            const orderItemLowOnStock = this.itemsLowOnStock.filter(itemLowOnStock => {
                return itemLowOnStock == this.item.id
            })
            return orderItemLowOnStock.length > 0
        }
    },
    methods: {
        deleteOrderItem(itemId) {
            if (confirm('Vai esi drošs?')) {
                this.$Progress.start()
                this.$store.dispatch('deleteOrderItem', {
                    orderItemId: itemId,
                    orderId: this.order.id
                }).then(resolve => {
                    this.$Progress.finish()
                })
            }
        },
        editItem(item) {
            this.$store.dispatch('setEditableItem', item)
        },
        showForm(formName) {
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName)
        },
    }
}
</script>

<style>
</style>