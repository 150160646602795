<template>
  <div v-if="form && order" class="space-y-6 w-full">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1">
        <Autocomplete
          placeholder="Meklēt preci no kataloga"
          v-model="form.autocompleteValue"
          :errors="errors.autocompleteValue"
          url="/api/fetch_catalog_items"
          :params="{ customer_id: order.customer.id, is_for_sale: 1 }"
          @autocompleteItem="getCatalogItem"
          :additionalFieldsBrackets="['sku']"
          :closeOnClick="false"
          @autocompleteClose="form.autocompleteValue = null"
        />
      </div>
    </div>

    <div class="grid grid-cols-1 gap-6">
      <template v-if="orderItems">
        <div class="divide-y divide-gray-200 dark:divide-gray-700">
          <template v-for="(item, index) in orderItems" :key="item.custom_uuid">
            <SingleOrderItemForm :item="item" @remove-product="removeOrderItem(index)" :customer="order.customer" />
          </template>
        </div>
      </template>
    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="!loading">
        <template v-if="orderItems.length > 0">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Pievienot preces
          </button>
        </template>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button @click.prevent="hideAddForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>
    </div>

  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";
import Calculations from "@/services/Calculations/calculations";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Autocomplete from "@/components/Components/Autocomplete";
import SingleOrderItemForm from "@/components/Orders/OrderForms/SingleOrderItemForm";

export default {
  name: "AddOrderItemForm",
  components: {
    Loading,
    Autocomplete,
    SingleOrderItemForm,
  },
  props: {
    order: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      vatRate: Calculations.defaultVatRate(),
      isLoadingCatalogItem: false,
      catalogItem: null,
      fetchedCatalogItems: [],
      searchCatalogItem: null,
      orderItems: [],
      form: {
        autocompleteValue: null,
      },
      formValidation: null
    }
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors",
    }),
  },
  watch: {
    catalogItem() {
      if(this.catalogItem) {
        this.addCatalogItemToOrderItems()
        this.catalogItem = null
        this.searchCatalogItem = null
        this.isLoadingCatalogItem = false
        this.fetchedCatalogItems = []
      }
    }
  },
  methods: {
    setFormatedOrderItemErrors() {
      this.formValidation = {}
      this.orderItems.map((item) => {
        this.formValidation[`price-${item.custom_uuid}`] = {rules: ['required', 'minVal0']};
        this.formValidation[`quantity-${item.custom_uuid}`] = {rules: ['required']};
      })
    },
    getFormatedOrderItemsValidationValues() {
      this.setFormatedOrderItemErrors()
      let form = {}
      form = this.form
      this.orderItems.map((item) => {
        form[`price-${item.custom_uuid}`] = item.price
        form[`quantity-${item.custom_uuid}`] = item.quantity
      })

      return form
    },
    getCatalogItem(value) {
            const val = {...value}
      val.custom_uuid = uuidv4()

      this.catalogItem = val
      this.$store.dispatch("setErrors", {})
    },
    addCatalogItemToOrderItems() {
      this.pushItemToOrderItems()
    },

    pushItemToOrderItems() {

      this.catalogItem.vatRate = this.catalogItem.category.reverse_vat ? 0 : this.vatRate
      this.catalogItem.quantity = 1
      this.catalogItem.discount = this.catalogItem.discount ? this.catalogItem.discount : 0
      this.catalogItem.totalDiscount = 0
      this.catalogItem.total = 0
      this.catalogItem.totalWithDiscount = 0
      this.catalogItem.totalVat = 0
      this.catalogItem.totalWithVat = 0
      this.catalogItem.branchId = null

      this.orderItems.unshift(this.catalogItem)
    },
    addOrderItemsQuantity() {
      this.orderItems.forEach( orderItem => {
        if (orderItem.id === this.catalogItem.id) {
          orderItem.quantity = ++orderItem.quantity
          const discountRate =  +orderItem.discount / 100
          const total = orderItem.price * orderItem.quantity
          orderItem.totalDiscount = (total * discountRate).toFixed(2)
          orderItem.total = (total - orderItem.totalDiscount).toFixed(2)
        }
      })
    },
    removeOrderItem(index) {
      this.orderItems.splice(index, 1);
    },
    formatOrderItems() {
      return this.orderItems.map( orderItem => {
        let formatedOrderItem = {}
        formatedOrderItem['orderable_id'] = orderItem.id
        formatedOrderItem['orderable_type'] = 'App\\CatalogItem'
        formatedOrderItem['description'] = orderItem.name
        formatedOrderItem['sku'] = orderItem.sku
        formatedOrderItem['price'] = String(orderItem.price)
        formatedOrderItem['quantity'] = orderItem.quantity
        formatedOrderItem['discount'] = orderItem.discount
        formatedOrderItem['vat_rate'] = orderItem.vatRate
        if(orderItem.branchId) {
          formatedOrderItem['branch_id'] = orderItem.branchId.id
        }
        return formatedOrderItem
      })
    },
    hideAddForm(){
      this.$store.dispatch('removeAllFormsForDisplay')
    },
    submit(){
      const form = this.getFormatedOrderItemsValidationValues()
      if (Validation(this.formValidation, form)) {
        this.$store.dispatch('addOrderItems', {
          orderId: this.$route.params.orderId,
          orderItems : this.formatOrderItems()
        })
      }
    }
  },
}
</script>