<template>

  <form class="space-y-6 w-full">

    <div class="grid grid-cols-12 gap-6 w-full">

      <template v-if="showInput">
        <div class="col-span-12">
          <Textarea name="code" placeholder="IMEI / Serial nr / Kodi" v-model="form.code" />
        </div>
        <div class="col-span-12 flex">
            <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out ml-auto mt-3" @click.prevent="validateCods">
              Pārbaudīt IMEI / Serial nr / Kodus
            </button>
        </div>
      </template>

      <template v-if="validationErrors">
        <div class="col-span-12 pt-4">
          <div class="w-full bg-gray-200 bg-opacity-70 dark:bg-gray-800 pl-4 pr-1 py-2 rounded-lg">
            <h3 class="text-xl font-semibold text-red-500 mb-3">Kļūdas</h3>
            <div class="w-full rounded-lg max-h-72 overflow-auto">
                  <p class="text-red-600 dark:text-red-200">{{validationErrors}}</p>
            </div>
          </div>
        </div>
      </template>

      <div class="col-span-12">
        <template v-if="form.allCodes && form.allCodes.length">
          <div class="w-full">
            <template v-for="(item, index) in form.allCodes" :key="item.id">
              <SingleAddOrderItemUnit :item="item" @remove="removeUnit" :errors="responseErrors && responseErrors[`unique_items.${index}.id`] ? responseErrors[`unique_items.${index}.id`] : null" />
            </template>
          </div>
        </template>
      </div>

    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="form.allCodes.length && !validationErrors">
        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </template>

      <button @click.prevent="clearForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>
    </div>
  </form>

</template>

<script>
import { mapGetters } from "vuex";
import Textarea from "@/components/Components/Textarea";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import SingleAddOrderItemUnit from "@/components/Orders/OrderItems/AddOrderItemUnits/SingleAddOrderItemUnit"

export default {
  name: "AddSingleOrderItemMultipleCodes",
  components: {
    Textarea,
    Loading,
    SingleAddOrderItemUnit,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
        code: null,
        allCodes: []
    },
    showInput: true,
    codeError: [],
    responseErrors: null,
    validationErrors: null,
  }),
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
    formValidation() {
      return {
      }
    }
  },
  methods: {
    clearForm() {
      this.$store.dispatch('getSingleOrder', this.$route.params.orderId)
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    removeUnit(value) {
      this.form.allCodes = this.form.allCodes.filter(item => item.id !== value)
      this.responseErrors = null
    },

    validateCods() {
      this.form.allCodes = []
      this.responseErrors = null
      this.validationErrors = null
      this.$Progress.start()
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('addMultipleCatalogItemUnits', {
          id : this.item.id,
          order_id: this.order.id,
          data : {
            items: this.form.code
          },
        })
        .then(response => {
          this.form.allCodes = response.data.data
          this.showInput = false;
        })
        .catch(error => {
          this.validationErrors = error.response.data.message
        })
      }  else this.$Progress.fail()
    },
    submit() {
      this.$Progress.start()
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('addCatalogItemUnits', {
          id : this.item.id,
          order_id: this.order.id,
          data : {
            unique_items: this.form.allCodes
          },
        })
        .catch(error => {
          this.responseErrors = error.response.data.errors
        })
      }  else this.$Progress.fail()
    }
  },
}
</script>

<style>
</style>