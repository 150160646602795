<template>

  <form class="space-y-6 w-full">

    <template v-if="item.quantity > form.allCodes.length">
      <div class="grid grid-cols-12 gap-6 w-full">
        <div class="col-span-12 sm:col-span-6">
          <Input name="code" placeholder="IMEI / Serial nr / Kods" v-model="form.code" @input="searchCode" :errors="codeError" />
        </div>
      </div>
    </template>

    <template v-if="form.allCodes && form.allCodes.length">
      <div class="w-full">
        <template v-for="(item, index) in form.allCodes" :key="item.id">
          <SingleAddOrderItemUnit :item="item" @remove="removeUnit" :errors="responseErrors && responseErrors[`unique_items.${index}.id`] ? responseErrors[`unique_items.${index}.id`] : null" />
        </template>
      </div>
    </template>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="form.allCodes.length && !responseErrors">
        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </template>

      <button @click.prevent="clearForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>

    </div>

  </form>

</template>

<script>
import { debounce } from 'vue-debounce'
import { mapGetters } from "vuex";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import SingleAddOrderItemUnit from "@/components/Orders/OrderItems/AddOrderItemUnits/SingleAddOrderItemUnit"

export default {
  name: "AddSingleOrderItemSingleCode",
  components: {
    Input,
    Loading,
    SingleAddOrderItemUnit,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    total: 0,
    form: {
        code: null,
        allCodes: [],
    },
    codeError: [],
    responseErrors: null,
  }),
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
    formValidation() {
      return {
      }
    }
  },
  methods: {
    clearForm() {
      this.$store.dispatch('getSingleOrder', this.$route.params.orderId)
      this.$store.dispatch("removeAllFormsForDisplay");
    },
    searchCode: debounce(function () {
        this.codeError = []

        if (this.form.code) {
          this.$store.dispatch('getSingleCatalogItemUnitByNumber', this.form.code)
              .then(response => {
                  const codeExists = this.form.allCodes.find(item => item.id === response.data.id)

                  if (!codeExists) {
                    this.form.allCodes.push(response.data)
                    this.form.code = ""
                  }
              })
              .catch(error => {
                  this.codeError = ['Nederīgs kods']
              })
        }
    }, 700),
    removeUnit(value) {
      this.form.allCodes = this.form.allCodes.filter(item => item.id !== value)
      this.responseErrors = null
    },
    submit() {
      this.responseErrors = null
      this.$Progress.start()
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch('addCatalogItemUnits', {
          id : this.item.id,
          order_id: this.order.id,
          data : {
            unique_items: this.form.allCodes
          },
        })
        .catch(error => {
          this.responseErrors = error.response.data.errors
        })
      }  else this.$Progress.fail()
    }
  },
}
</script>

<style>
</style>