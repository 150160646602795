<template>
    <div class="bg-gray-200 dark:bg-gray-800 px-3 py-2 rounded-lg mb-1">
        <div class="flex w-full py-1">
            <div class="w-full grid grid-cols-12 px-2 rounded-lg">
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <ItemText title="Piegādātājs" :text="item.supplier.name" />
                </div>
                
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <ItemText title="Cena" :text="item.procurement_price" />
                </div>

                <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                    <ItemText title="IMEI / Serial nr / Kods" :text="item.number" />
                </div>

                <template v-if="item.procurement.supplier_uuid">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                        <ItemText title="Piegādātāja PPR nr" :text="item.procurement.supplier_uuid" />
                    </div>
                </template>
            </div>

            <Button icon="close" @click="removeItem" />
        </div>
        <template v-if="errors">
            <template v-for="error in errors" :key="error">
                <div class="w-full text-red-400">
                    {{error}}
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
    name: "SingleAddOrderItemUnit",
    components: {
        ItemText,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        errors: {}
    },
    emits: ['remove'],
    methods: {
        removeItem() {
            this.$emit('remove', this.item.id)
        }
    }
}
</script>

<style>

</style>