<template>
    <div class="flex w-full py-1">
        <div class="w-full grid grid-cols-12 px-2 rounded-lg">
            <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                <ItemText title="Piegādātājs">
                    <template v-slot:content>
                        <router-link :to="`/stock/procurements/${item.procurement_item.procurement_id}`" target="_blank" class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1">
                            {{item.procurement_item.procurement.supplier.name}}
                        </router-link>
                    </template>
                </ItemText>
            </div>
            
            <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                <ItemText title="Cena" :text="item.procurement_item.price" />
            </div>

            <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                <ItemText title="IMEI / Serial nr / Kods">
                    <template v-slot:content>
                        <div class="flex items-center">
                            <router-link :to="`/catalog_item_units/${item.id}`" target="_blank" class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1 mr-1">
                                {{item.number}}
                            </router-link>
                            <Button icon="copy" tooltip="Kopēt IMEI / Serial nr / Kodu" @click="copyText(item.number)" />
                        </div>
                    </template>
                </ItemText>
            </div>

            <template v-if="item.supplier_catalog_item">
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <ItemText title="Piegādātāja preces kods" :text="item.supplier_catalog_item.external_id" />
                </div>
            </template>

            <template v-if="item.credit_note">
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <ItemText title="Credit Note">
                        <template v-slot:content>
                            <div class="flex items-center">
                                <router-link :to="`/invoices/credit_notes/${item.credit_note.id}`" target="_blank" class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1 mr-1">
                                    {{item.credit_note.uuid}}
                                </router-link>
                            </div>
                        </template>
                    </ItemText>
                </div>
            </template>
        </div>


        <template v-if="order.status.id !== 3">
            <Button icon="delete" @click="deleteUnit" />
        </template>
    </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
    name: "SingleOrderItemUnit",
    components: {
        ItemText,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
    },
    methods: {
        deleteUnit() {
            this.$store.dispatch('deleteCatalogItemUnit', {
                id: this.item.id,
                order_id: this.order.id,
            })
        },
        copyText(text) {
            var dummy = document.createElement("textarea");
            // to avoid breaking orgain page when copying more words
            // cant copy when adding below this code
            // dummy.style.display = 'none'
            document.body.appendChild(dummy);
            //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        }
    }
}
</script>